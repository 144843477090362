import React,{ useState,useEffect } from 'react';
import { useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import Table from '../components/Table/Table';
import { generateRandomId } from '../utils';

export default function Earningreports() {
  const { t } = useTranslation();

    const columns =  [
        { headerName: t('year'), field: 'year', align: 'center', flex: 1 },
        { headerName: t('months'), field: 'monthsName', align: 'center', flex: 1 },
        { headerName: t('Gross_trip_cost'), field: 'tripCost', align: 'center', flex: 1 },
        { headerName: t('trip_cost_driver_share'), field: 'rideCost', align: 'center', flex: 1 },
        { headerName: t('convenience_fee'), field: 'convenienceFee', align: 'center', flex: 1 },
        { headerName: t('Discounts'), field: 'discountAmount', align: 'center', flex: 1 },
        { headerName: t('Profit'), field: 'profit', align: 'center', flex: 1,  renderCell: ({ row }) =>  (parseFloat(row.convenienceFee) - parseFloat(row.discountAmount)).toFixed(2) , editable: false },
        
    ];

  const [data, setData] = useState([]);
  const earningreportsdata = useSelector(state => state.earningreportsdata);

  useEffect(()=>{
        if(earningreportsdata.Earningreportss){
          earningreportsdata.Earningreportss.forEach(row => row.id = generateRandomId())
          setData(earningreportsdata.Earningreportss);
        }
  },[earningreportsdata.Earningreportss]);

  return (
    earningreportsdata.loading? <CircularLoading/> :
    <Table
      title={t('earning_reports')}
      columns={columns}
      data={data}
    />
  );
}
