import React,{ useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import Table from '../components/Table';

export default function NotificationHistory() {
  const { t } = useTranslation();
  const columns =  [
    { headerName: t('Date'), field: 'date' },
    {
      headerName: t('device_type'),
      field: 'devicetype',
      type: 'singleSelect',
      valueOptions: [ 'All', 'Android', 'iOS' ],
    },
    {
      headerName: t('user_type'),
      field: 'usertype',
      type: 'singleSelect',
      valueOptions: [t('rider'), t('driver')],
    },
    { headerName: t('title'), field: 'title'},
    { headerName: t('body'), field: 'body' },
  ];

  const [data, setData] = useState([]);
  const notificationdata = useSelector(state => state.notificationdata);

  useEffect(()=>{
        if(notificationdata.notifications){
            setData(notificationdata.notifications);
        }else{
            setData([]);
        }
  },[notificationdata.notifications]);

  return (
    notificationdata.loading ? <CircularLoading/> :
    <Table 
      title={"Notification History"}
      columns={columns}
      data={data}
    />
  );
}
