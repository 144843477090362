import React,{ useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import Table from 'components/Table';

export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const settings = useSelector(state => state.settingsdata.settings);
  const {
    addUser,
    editUser, 
    deleteUser,
    checkUserExists
  } = api;
  const [data, setData] = useState([]);
  const usersdata = useSelector(state => state.usersdata);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(usersdata.users){
        setData(usersdata.users.filter(user => user.usertype ==='fleetadmin'));
    }else{
      setData([]);
    }
  },[usersdata.users]);

  const columns = [
    { headerName: t('Created'), field: 'createdAt', defaultSort:'desc', renderCell: ({ value }) => value ? moment(value).format('lll') : null },
    { headerName: t('First'), field: 'firstName', editable: true },
    { headerName: t('Last'), field: 'lastName', editable: true },
    { headerName: t('Email'), field: 'email', editable: true, renderCell: ({ value }) => settings.AllowCriticalEditsAdmin ? value : "Hidden for Demo" },
    { headerName: t('Mobile'), field: 'mobile', editable: true, renderCell: ({ value }) => settings.AllowCriticalEditsAdmin ? value : "Hidden for Demo" },
    { headerName: t('Profile Img'),  field: 'profile_image', renderCell: ({ value }) => value ? <img alt='Profile' src={value} style={{width: 50,borderRadius:'50%'}}/> : null },
    { headerName: t('Approval'),  field: 'approved', type:'boolean' }
  ];

  const onAddRow = (newData) => {
    checkUserExists(newData).then((res) => {
      if (res.users && res.users.length > 0) {
        alert(t('user_exists'));
      }
      else if(res.error){
        alert(t('email_or_mobile_issue'));
      }
      else{
        newData['createdByAdmin'] = true;
        newData['usertype'] = 'fleetadmin';
        newData['createdAt'] = new Date().toISOString();
        newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
        dispatch(addUser(newData));
      }
    });
  };

  const onUpdate = (newData) => {
    dispatch(editUser(newData.id, newData));
  };

  const onDelete = (id) => {
    settings.AllowCriticalEditsAdmin ?
      dispatch(deleteUser(id)) :
      alert(t('Demo Mode'));
  };

  return (
    usersdata.loading ?
      <CircularLoading/> :
      <Table
        title={t('Fleet Admins')}
        columns={columns}
        data={data}
        isEditable
        onSaveNewRow={onAddRow}
        onUpdateRow={onUpdate}
        onDeleteRow={onDelete}
      />
  );
}
