import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import Table from 'components/Table';

export default function Promos() {
  const { api } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    editPromo
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);

  const columns = [
    // { headerName: t('promo_name'), field: 'promo_name', editable: true },
    { headerName: t('promo_name'), field: 'promo_name', editable: true },
    { headerName: t('description'), field: 'promo_description', editable: true },
    {
      headerName: 'Code Type',
      field: 'promo_discount_type',
      type: 'singleSelect',
      editable: true,
      valueOptions: [t('flat'), t('percentage')],
    },
    { headerName: t('promo_discount_value'), field: 'promo_discount_value', type: 'number', editable: true },
    { headerName: t('max_limit'), field: 'max_promo_discount_value', type: 'number', editable: true },
    { headerName: t('min_limit'), field: 'min_order', type: 'number', editable: true },
    { headerName: t('end_date'), width: 175, field: 'promo_validity', editable: true, type: 'date', renderCell: ({ value }) => value ? moment(value).format('lll') : null },
    { headerName: t('promo_usage'), field: 'promo_usage_limit', type: 'number', editable: true },
    { headerName: t('promo_used_by'), field: 'user_avail' }
  ];

  const [data, setData] = useState([]);
  const promodata = useSelector(state => state.promodata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (promodata.promos) {
      setData(promodata.promos);
    } else {
      setData([]);
    }
  }, [promodata.promos]);

  const onAddRow = (newData) => {
    newData['createdAt'] = new Date().toISOString();
    dispatch(editPromo(newData,"Add"));
  };

  const onUpdate = (newData) => {
    settings.AllowCriticalEditsAdmin ?
      dispatch(editPromo(newData,"Update")) :
      alert(t('demo_mode'));
  };

  const onDelete = (id) => {
    settings.AllowCriticalEditsAdmin ?
      dispatch(editPromo(id, "Delete")) :
      alert(t('demo_mode'));
  };

  return (
    promodata.loading ? <CircularLoading /> :
      <Table
        // title={t('promo_offer')}
        title={'Promo Codes'}
        columns={columns}
        data={data}
        isEditable
        onSaveNewRow={onAddRow}
        onUpdateRow={onUpdate}
        onDeleteRow={onDelete}
      />
  );
}
