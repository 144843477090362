// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
 
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: "AIzaSyAwyKuZf8EoNRFwXcZh2hHj6bEVdUkwX8g",
  authDomain: "redoos3.firebaseapp.com",
  databaseURL: "https://redoos3-default-rtdb.firebaseio.com",
  projectId: "redoos3",
  storageBucket: "redoos3.appspot.com",
  messagingSenderId: "9212846933",
  appId: "1:9212846933:web:95c7d0983db32161f3c5dc",
  measurementId: "G-89KDBMWHGE"
};

export default config;

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);