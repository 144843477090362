import React, { useContext, useState } from 'react';
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
// import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';

import GridOnIcon from '@material-ui/icons/GridOn';

// import GearIcon from '@material-ui/icons/Gear';
import CarIcon from '@material-ui/icons/DirectionsCar';
import { AddAlarm, CalendarToday, PlaylistAdd } from '@material-ui/icons';
import { ViewList } from '@material-ui/icons';
import PowerIcon from '@material-ui/icons/Power';
import { AssessmentOutlined } from '@material-ui/icons';
import ListIcon from '@material-ui/icons/ListAlt';
import ExitIcon from '@material-ui/icons/ExitToApp';
// import OfferIcon from '@material-ui/icons/LocalOffer';
import SettingIcon from '@material-ui/icons/Settings';
import RedeemIcon from  '@material-ui/icons/Redeem';
import PeopleIcon from '@material-ui/icons/People';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import NotifyIcon from '@material-ui/icons/NotificationsActive';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { FirebaseContext } from 'common';
import { colors } from '../components/Theme/WebTheme';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from "react-i18next";


function AppMenu() {
  const { api } = useContext(FirebaseContext);
  const {
    signOut
  } = api;
  const { t } = useTranslation();
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(signOut());
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

  const classes = useStyles();

  const [menuActive, setMenuActive] = useState([false, false, false, false]);

  const handleClick = (index) => {
    let temp = menuActive;
    temp[index] = !menuActive[index];
    setMenuActive(temp);
  };
  const arrow = {
    justifyContent:'flex-end',
     position:'absolute',
     right:0,
  };

  let isAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'admin';
  let isFleetAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'fleetadmin';
  return (
    <div>
      <div style={{ display: 'flex',justifyContent: 'center', backgroundColor: colors.AppManu_Header }}>
        <img 
          style={{ marginTop: '20px', marginBottom: '20px', width: '140px', height: '140px' }}
          src="logo1024x1024.png"
          alt="ReDoos" />
      </div>
      <Divider />
      <MenuList>
        {/* <MenuItem component={Link} to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('home')}</Typography>
        </MenuItem> */}



        
        {isAdmin || isFleetAdmin ?
          <MenuItem component={Link} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            {/* <Typography variant="inherit">{t('dashboard_text')}</Typography> */}
            <Typography variant="inherit">{t('dashboard_text')}</Typography>
          </MenuItem>
          : null}


        {isAdmin || isFleetAdmin ?
          <MenuItem component={Link} to="/dashboard">
            <ListItemIcon>
              <AssessmentOutlined />
            </ListItemIcon>
            {/* <Typography variant="inherit">{t('dashboard_text')}</Typography> */}
            <Typography variant="inherit">{"Reports"}</Typography>
          </MenuItem>
          : null}


        {isAdmin ?
          <MenuItem button onClick={() => handleClick(1)} component={Link} to="/riders">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            {/* <Typography>{t('users_title')}</Typography> */}
            <Typography>{'Users'}</Typography>
            {menuActive[1] ?
              <ListItemIcon  style={arrow}>
                <KeyboardArrowDownIcon/>
              </ListItemIcon>
              :
              <ListItemIcon  style={arrow}>
                <KeyboardArrowLeftIcon/>
              </ListItemIcon>
            }
          </MenuItem>
          : null}

        <Collapse in={menuActive[1]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/riders">
                <ListItemIcon>
                  <PeopleIcon/>
                </ListItemIcon>
                <Typography variant="inherit">{t('riders')}</Typography>
              </ListItem>
              : null}
            {isAdmin || isFleetAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/drivers">
              <ListItemIcon>
                <CarIcon />
              </ListItemIcon>
                <Typography variant="inherit">{t('drivers')}</Typography>
              </ListItem>
              : null}
            {/* {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/fleetadmins">
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('fleetadmins')}</Typography>
              </ListItem>
              : null} */}
          </List>
        </Collapse>


        {isAdmin ?
        <MenuItem button onClick={() => handleClick(2)} component={Link} to="/bookings">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            {/* <Typography>{t('users_title')}</Typography> */}
            <Typography>{'Bookings'}</Typography>
            {menuActive[2] ?
              <ListItemIcon  style={arrow}>
                <KeyboardArrowDownIcon/>
              </ListItemIcon>
              :
              <ListItemIcon  style={arrow}>
                <KeyboardArrowLeftIcon/>
              </ListItemIcon>
            }
          </MenuItem>
          : null}
      <Collapse in={menuActive[2]} timeout="auto" unmountOnExit>
        {/* <MenuItem button onClick={() => handleClick(2)} component={Link} to="/bookings"> */}
        {/* <MenuItem component={Link} to="/bookings">*/}
        <ListItem button className={classes.nested} component={Link} to="/bookings">
          <ListItemIcon>
            <ViewList />
          </ListItemIcon>
          {/* <Typography variant="inherit">{t('booking_history')}</Typography> */}
          <Typography variant="inherit">{t('booking_history')}</Typography>
          </ListItem>
        {isAdmin ?
          <ListItem button className={classes.nested} component={Link} to="/addbookings">
            <ListItemIcon>
              < PlaylistAdd/>
            </ListItemIcon>
            <Typography variant="inherit">{t('addbookinglable')}</Typography>
          </ListItem>
          : null}

        </Collapse>
        
        


        {isAdmin ?
        <MenuItem button onClick={() => handleClick(3)} component={Link} to="/driverrewards">
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <Typography>{'Rewards'}</Typography>
            {menuActive[3] ?
              <ListItemIcon  style={arrow}>
                <KeyboardArrowDownIcon/>
              </ListItemIcon>
              :
              <ListItemIcon  style={arrow}>
                <KeyboardArrowLeftIcon/>
              </ListItemIcon>
            }
          </MenuItem>
          : null}
      <Collapse in={menuActive[3]} timeout="auto" unmountOnExit>
        {isAdmin ?
          <ListItem button className={classes.nested} component={Link}to="/driverrewards">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            {/* <Typography variant="inherit">{t('car_type')}</Typography> */}
            <Typography variant="inherit">{'Driver Rewards'}</Typography>
          </ListItem>
          : null}
        {isAdmin ?
          <ListItem button className={classes.nested} component={Link}to="/riderrewards">
            <ListItemIcon>
              <CarIcon />
            </ListItemIcon>
            {/* <Typography variant="inherit">{t('cancellation_reasons')}</Typography> */}
            <Typography variant="inherit">{'Rider Rewards'}</Typography>
          </ListItem>
          : null}
        </Collapse>
        {/* {isAdmin ?
          <MenuItem button onClick={() => handleClick(2)} component={Link} to="/earningreports">
            <ListItemIcon>
              <MoneyIcon />
            </ListItemIcon>
            <Typography>{t('wallet_title')}</Typography>
            {menuActive[2] ?
              <ListItemIcon style={arrow}>
                <KeyboardArrowDownIcon/>
              </ListItemIcon>
              :
              <ListItemIcon style={arrow}>
                <KeyboardArrowLeftIcon/>
              </ListItemIcon>
            }
          </MenuItem>
          : null}
        <Collapse in={menuActive[2]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/earningreports">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('earning_reports')}</Typography>
              </ListItem>
              : null}
            {isAdmin || isFleetAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/driverearning">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('driver_earning')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/addtowallet">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('add_to_wallet')}</Typography>
              </ListItem>
              : null}
            {isAdmin ?
              <ListItem button className={classes.nested} component={Link} to="/withdraws">
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <Typography variant="inherit">{t('withdraws')}</Typography>
              </ListItem>
              : null}
          </List>
        </Collapse> */}
        {isAdmin ?
          <MenuItem button onClick={() => handleClick(4)} component={Link} to="/notifications">
            <ListItemIcon>
              <NotifyIcon />
            </ListItemIcon>
            <Typography variant="inherit">{'Notifications'}</Typography>
            {menuActive[4] ?
              <ListItemIcon  style={arrow}>
                <KeyboardArrowDownIcon/>
              </ListItemIcon>
              :
              <ListItemIcon  style={arrow}>
                <KeyboardArrowLeftIcon/>
              </ListItemIcon>
            }
          </MenuItem>
          : null}
        
        {
          isAdmin? <Collapse in={menuActive[4]} timeout="auto" unmountOnExit>
              <ListItem button className={classes.nested} component={Link}to="/notifications">
                <ListItemIcon>
                  <CalendarToday />
                </ListItemIcon>
                {/* <Typography variant="inherit">{t('car_type')}</Typography> */}
                <Typography variant="inherit">{'Schedule'}</Typography>
              </ListItem>
              <ListItem button className={classes.nested} component={Link}to="/notificationhistory">
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                {/* <Typography variant="inherit">{t('cancellation_reasons')}</Typography> */}
                <Typography variant="inherit">{'History'}</Typography>
              </ListItem>
          </Collapse> : null
        }
        {isAdmin ?
          <MenuItem component={Link} to="/settings">
            <ListItemIcon>
              <SettingIcon />
            </ListItemIcon>
            {/* <Typography variant="inherit">{t('settings_title')}</Typography> */}
            <Typography variant="inherit">{'Settings'}</Typography>
          </MenuItem>
          : null}
        {/* <MenuItem component={Link} to="/profile">
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('profile')}</Typography>
        </MenuItem> */}
        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <Typography variant="inherit">{t('logout')}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;