import React,{ useState, useEffect, useContext } from 'react';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import CheckIcon from '@material-ui/icons/Check';
import Table from '../components/Table';
import { toStrictBoolean } from '../utils';

const Withdraws = () => {
  const { api } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    completeWithdraw
  } = api;
  const dispatch = useDispatch();
  const columns =  [
      { headerName: 'ID', field: 'id', hide: true, disableExport: true },
      { headerName: t('requestDate'), field: 'date' },
      { headerName: t('driver_name'),field: 'name'},
      { headerName: t('amount'), field: 'amount' },
      { headerName: t('processed'), field: 'processed', type: 'boolean'},  
      { headerName: t('processDate'), field: 'procesDate'}, 
      { headerName: t('bankName'), field: 'bankName' },
      { headerName: t('bankCode'), field: 'bankCode' },
      { headerName: t('bankAccount'), field: 'bankAccount' }, 
  ];
  const [data, setData] = useState([]);
  const withdrawdata = useSelector(state => state.withdrawdata);

  useEffect(()=>{
        if(withdrawdata.withdraws){
            setData(withdrawdata.withdraws);
        }else{
          setData([]);
        }
  },[withdrawdata.withdraws]);
  
  return (
    withdrawdata.loading? <CircularLoading/> :
    <Table
      title={t('booking_title')}
      columns={columns}
      data={data}
      actions={[
        {
          icon: <CheckIcon />,
          tooltip: t('process_withdraw'),
          disabled: (rowData) => toStrictBoolean(rowData.processed),
          onClick: (event, rowData) => {
            dispatch(completeWithdraw(rowData));
          }         
        }
      ]}
    />
  );
}

export default Withdraws;
