export const colors = {
    LandingPage_Sec_info : '#F1F2F1',
    LandingPage_Background: '#E9E9E1',
    Landing_description: '#373737',
    Btn_Primary: '#154a78',
    Btn_Secondary: '#154a78', 
    Header_background: '#FFFFFF',
    header_content: '#000000',
    AppManu_Header: '#154a78',
    RespossiveDrawer_Header: '#154a78',
}