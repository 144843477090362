import React, { useState, useEffect, useContext,useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import Table from '../components/Table';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 780,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CarTypes() {
  const { api, appcat } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const settings = useSelector(state => state.settingsdata.settings);
  const {
    editCarType
  } = api;
  const columns = [
    { headerName: t('image'), field: 'image', align: 'center', renderCell: ({ value }) => <img alt='Car' src={value} style={{ width: 50 }}/> },
    { headerName: 'Base Points', field: 'base_fare', type: 'number', editable: true },
    { headerName: 'Points per mile/km', width: 175, field: 'rate_per_unit_distance', type: 'number', editable: true },
    { headerName: 'Points per hour', field: 'rate_per_hour', type: 'number', editable: true },
    // { headerName: t('min_fare'), field: 'min_fare', type: 'number', editable: true },
    // { headerName: t('convenience_fee'), width: 175, field: 'convenience_fees', type: 'number', editable: true },
    {
      headerName: t('Fee Type'),
      field: 'convenience_fee_type',
      type: 'singleSelect',
      valueOptions: [t('flat'), t('percentage')],
      editable: true
    },
    // { headerName: t('extra_info'), field: 'extra_info', width: 250 }
  ];

  const subcolumns = [
    { title: t('description'), field: 'description', render: (rowData) => <span>{rowData.description}</span> },
    { title: t('amount'), field: 'amount', type: 'numeric' }
  ];

  const subcolumns2 = [
    { title: t('minsDelayed'), field: 'minsDelayed', render: rowData => <span>{rowData.minsDelayed}</span> },
    { title: t('amount'), field: 'amount', type: 'numeric' }
  ];

  const [data, setData] = useState([]);
  const cartypes = useSelector(state => state.cartypes);
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const classes = useStyles();
  const [open,setOpen] = useState(false);
  const [rowData,setRowData] = useState();
  const [modalType,setModalType] = useState();

  const handleClose = () => {
    setOpen(false);
  }

  const onRowAdd = (newData) => {
    if (settings.AllowCriticalEditsAdmin) {
      newData['createdAt'] = new Date().toISOString();
      dispatch(editCarType(newData,"Add"));
    } else {
      alert(t('demo_mode'));
    }
  }

  const onRowUpdate = (newData) => {
    if (settings.AllowCriticalEditsAdmin) {
      dispatch(editCarType(newData, "Update"));
    } else {
      alert(t('demo_mode'));
    }
  }

  const onDelete = (id) => {
    if (settings.AllowCriticalEditsAdmin) {
      dispatch(editCarType({ id: id },"Delete"));
    } else {
      alert(t('demo_mode'));
    }
  }

  useEffect(() => {
    if (cartypes.cars) {
      setData(cartypes.cars);
    } else {
      setData([]);
    }
  }, [cartypes.cars]);

  return (
    cartypes.loading ? <CircularLoading /> :
    <div ref={rootRef}>
      <Table 
        // title={t('car_type')}
        title={'Manage Cars'}
        columns={columns}
        data={data}
        isEditable
        onSaveNewRow={onRowAdd}
        onUpdateRow={onRowUpdate}
        onDeleteRow={onDelete}
        actions={[
          appcat === 'delivery' ? {
            icon: <PhotoSizeSelectSmallIcon />,
            onClick: (event, rowData) => {
              setModalType('parcelTypes')
              setRowData(rowData);
              setOpen(true);
            }
          } : null,
          appcat === 'delivery' ? {
            icon: <FitnessCenterIcon />,
            onClick: (event, rowData) => {
              setModalType('options')
              setRowData(rowData);
              setOpen(true);
            }
          } : null,
          {
            icon: <CancelScheduleSendIcon />,
            onClick: (event, rowData) => {
              setModalType('cancelSlab')
              setRowData(rowData);
              setOpen(true);
            }
          }
        ].filter(i => i !=null )}
      />
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={handleClose}
        open={open}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <MaterialTable
            title={modalType === 'options'?t('options') :( modalType === 'cancelSlab' ? t('cancelSlab'): t('parcel_types'))}
            columns={modalType === 'cancelSlab'? subcolumns2 :subcolumns }
            data={(rowData && rowData[modalType]) ? rowData[modalType]:[]}
            options={{
              exportButton: true,
            }}
            editable={{
              onRowAdd: newData =>
                settings.AllowCriticalEditsAdmin?
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    if(!rowData[modalType]){
                      rowData[modalType] = [];
                    }
                    rowData[modalType].push(newData);
                    dispatch(editCarType(rowData), "Update");
                  }, 600);
                })
                :
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    alert(t('demo_mode'));
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                settings.AllowCriticalEditsAdmin?
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    let tblData = data;
                    rowData[modalType][rowData[modalType].indexOf(oldData)] = newData;
                    dispatch(editCarType(rowData), "Update");
                  }, 600);
                })
                :
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    alert(t('demo_mode'));
                  }, 600);
                }),
              onRowDelete: oldData =>
                settings.AllowCriticalEditsAdmin?
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    let tblData = data;
                    rowData[modalType].splice(rowData[modalType].indexOf(oldData), 1);
                    dispatch(editCarType(rowData), "Update");
                  }, 600);
                })
                :
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    alert(t('demo_mode'));
                  }, 600);
                }),
            }}  
          />
        </div>
      </Modal>
    </div>
  );
}
