import React,{ useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import Table from 'components/Table';
import { generateRandomId } from '../utils';

export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    addUser,
    editUser, 
    deleteUser,
    checkUserExists
  } = api;
  const [data, setData] = useState([]);
  const [cars, setCars] = useState({});
  const usersdata = useSelector(state => state.usersdata);
  const cartypes = useSelector(state => state.cartypes);
  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(usersdata.users){
        setData(usersdata.users.filter(user => user.usertype ==='driver' && ((user.fleetadmin === auth.info.uid && auth.info.profile.usertype === 'fleetadmin')|| auth.info.profile.usertype === 'admin')));
    }else{
      setData([]);
    }
  },[usersdata.users,auth.info.profile.usertype,auth.info.uid]);

  useEffect(()=>{
    if(cartypes.cars){
        let obj =  {};
        cartypes.cars.map((car)=> obj[car.name]=car.name)
        setCars(obj);
    }
  },[cartypes.cars]);

  const columns = [
      // { headerName: t('Created'), field: 'createdAt', colId: 'createdAt', editable: false, sortable: true, defaultSort:'desc', width: 175, renderCell: ({ value }) => value ? moment(value).format('lll') : null},
      { headerName: t('First'), field: 'firstName', editable: true, colId: 'firstName', },
      { headerName: t('Last'), field: 'lastName', editable: true, colId: 'lastName', },
      { headerName: t('Email'), field: 'email', colId: 'email', editable: true, width: 200, renderCell: ({ value }) => settings.AllowCriticalEditsAdmin ? value : "Hidden for Demo"},
      { headerName: t('Mobile'), field: 'mobile', editable: true, colId: 'mobile', renderCell: ({ value }) => settings.AllowCriticalEditsAdmin ? value : "Hidden for Demo"},
      { headerName: t('Profile Img'),  field: 'profile_image', renderCell: ({ value }) => value ? <img alt='Profile' src={value} style={{width: 50,borderRadius:'50%'}}/> : null },
      { headerName: t('Vehicle Model'), field: 'vehicleMake', editable: true },
      { headerName: t('Model #'), field: 'vehicleModel', editable: true },
      { headerName: t('Registration #'), field: 'vehicleNumber', editable: true },
      // { headerName: t('Other Info'), field: 'other_info', editable: true },
      // { headerName: t('Car Type'), field: 'carType', lookup: cars, editable: true,},
      { headerName: t('Approval'),  field: 'approved', type:'boolean', editable: true },
      { headerName: t('Active'),  field: 'driverActiveStatus', type:'boolean', editable: true },
      { headerName: t('License Img'),  field: 'licenseImage', renderCell: ({ value }) => value ? <img alt='License' src={value} style={{ width: 100 }}/> : null },
      { headerName: 'Points',  field: 'walletBalance' },
      { headerName: t('Rating'), field: 'ratings', renderCell: ({ value }) => value?.userrating ? value.userrating : "0" },
      { headerName: t('Referred'), field: 'signupViaReferral', editable: false },
      { headerName: t('Referral ID'),  field: 'referralId' },
      // { headerName: t('Bank Name'), field: 'bankName', editable: true },
      // { headerName: t('Bank Code'), field: 'bankCode', editable: true  },
      // { headerName: t('Bank Acct'), field: 'bankAccount', editable: true  },
      { headerName: 'Busy Queue',  field: 'queue', type:'boolean', editable: true },
  ];

  const addNewDriver = (newData) => {
    newData['createdByAdmin'] = true;
    newData['usertype'] = 'driver';
    newData['createdAt'] = new Date().toISOString();
    newData['referralId'] = newData.firstName.toLowerCase() + generateRandomId();
    let role = auth.info.profile.usertype;
    if(role === 'fleetadmin'){
      newData['fleetadmin'] = auth.info.uid; 
    }
    dispatch(addUser(newData));
  }

  const onUpdate = (newData) => {
    checkUserExists(newData)
      .then((res) => {
        if (res.users && res.users.length > 0 && !res.error) {
          dispatch(editUser(newData.id, newData));
        }
        else if(res.error){
          const msg = res.error;
          alert(msg);
        }
        else {
          addNewDriver(newData);
        }
    });
  };

  const onDelete = (id) => {
    if (settings.AllowCriticalEditsAdmin) {
      dispatch(deleteUser(id));
    } else {
      alert(t('Demo Mode'));
    }
  };

  const onAddRow = (newData) => {
    checkUserExists(newData)
      .then((res) => {
        if (res.users && res.users.length > 0) {
          alert('User already exsits!');
        }
        else if(res.error){
          const msg = res.error;
          alert(msg);
        }
        else {
          addNewDriver(newData);
        }
    });
  };

  return (
    usersdata.loading? <CircularLoading/> :
    <Table 
      title={'Manage Drivers'}
      columns={columns}
      data={data}
      isEditable
      onSaveNewRow={onAddRow}
      onUpdateRow={onUpdate}
      onDeleteRow={onDelete}
    />
  );
}
