import React,{ useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import Table from 'components/Table';
import { generateRandomId } from '../utils';

export default function DriverRewards() {
  const { api } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    editCarType,
  } = api;
  const [data, setData] = useState([]);
  const usersdata = useSelector(state => state.usersdata);
  const cartypes = useSelector(state => state.cartypes);
  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(cartypes?.cars){
      setData(cartypes.cars);
    }
  },[cartypes?.cars]);

  const columns = [
    { headerName: t('name'), field: 'name', width: 100 },
    { headerName: t('image'), field: 'image', width: 100, renderCell: ({ value }) => value ? <img alt='Profile Image' src={value} style={{width: 50}}/> : null },
    { headerName: t('base_fare'), field: 'base_fare', type: 'number', editable: true },
    { headerName: t('rate_per_unit_distance'), width: 175, field: 'rate_per_unit_distance', type: 'number', editable: true },
    { headerName: t('rate_per_hour'), field: 'rate_per_hour', type: 'number', editable: true },
    { headerName: t('min_fare'), field: 'min_fare', type: 'number', editable: true },
    { headerName: t('convenience_fee'), width: 175, field: 'convenience_fees', type: 'number', editable: true },
    {
      headerName: t('convenience_fee_type'),
      field: 'convenience_fee_type',
      type: 'singleSelect',
      valueOptions: [t('flat'), t('percentage')],
      editable: true
    },
  ];

  const onUpdate = (newData) => {
    if (settings.AllowCriticalEditsAdmin) {
      dispatch(editCarType(newData, "Update"));
    } else {
      alert(t('demo_mode'));
    }
  };
  
  return (
    usersdata.loading? <CircularLoading/> :
    <Table 
      title={'Drivers Rewards'}
      columns={columns}
      data={data}
      isEditable
      onUpdateRow={onUpdate}
    />
  );
}
