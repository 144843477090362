import React,{ useState,useEffect } from 'react';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import Table from '../components/Table';
import { generateRandomId } from '../utils';

export default function DriverEarning() {
  const { t } = useTranslation();
    const columns =  [
        { headerName: t('year'),field: 'year', align: 'center' },
        { headerName: t('months'), field: 'monthsName', align: 'center'  },
        { headerName: t('driver_name'), field: 'driverName', flex: 1, align: 'center' },
        { headerName: t('vehicle_reg_no'), field: 'driverVehicleNo', flex: 1, align: 'center'  },
        { headerName: t('earning_amount'), field: 'driverShare', flex: 1, align: 'center' },
        
    ];

  const [data, setData] = useState([]);
  const driverearningdata = useSelector(state => state.driverearningdata);

  useEffect(()=>{
    if(driverearningdata.driverearnings){
      driverearningdata.driverearnings.forEach(val => val.id = generateRandomId());
      setData(driverearningdata.driverearnings);
    }
  },[driverearningdata.driverearnings]);

  return (
    driverearningdata.loading? <CircularLoading/> :
    <Table 
      title={t('driver_earning')}
      columns={columns}
      data={data}
    />
  );
}
