import React, { useMemo, useState } from "react";
import {
  Typography,
  Collapse,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';

import { 
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
  GridToolbarExport } from '@mui/x-data-grid-pro';

export default function Table(props) {
    const { 
      title,
      columns,
      data,
      actions: propActions = [],
      pageSize = 10,
      isEditable,
      onSaveNewRow,
      onUpdateRow,
      onDeleteRow,
      quickSearch = true,
      showOptions = true,
     } = props;

    const [isEditMode, setIsEditMode] = useState(false);
    const [deleteId, setDeleteId] = useState();

    const apiRef = useGridApiRef();

    const toggleUneditableColumns = (hide) => {
      columns.forEach(colDef => {
        if (!colDef.editable) {
          colDef.hide = hide;
          // apiRef.current.setColumnVisibility(colDef.field, hide !== true);
        }
      });
    }

    const onClickAddRow = () => {
      toggleUneditableColumns(true);
      const id = Math.floor(1000 + Math.random() * 99999).toString();
      apiRef.current.updateRows([{ id, isNew: true }]);
      apiRef.current.setRowMode(id, 'edit');
      setIsEditMode(true);

      // Wait for the grid to render with the new row
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: apiRef.current.getRowsCount() - 1,
        });
  
        apiRef.current.setCellFocus(id, columns[0].field);
      }, 150);
    };

    const CustomToolbar = (props) => {
      const options = [
        <GridToolbarColumnsButton />,
        <GridToolbarFilterButton />,
        <GridToolbarDensitySelector />,
        <GridToolbarExport />
      ];
      return (
        <Collapse in>
            <GridToolbarContainer>
                { quickSearch ? <GridToolbarQuickFilter debounceMs={300} /> : null }
                { isEditable && onSaveNewRow ? <Button disabled={isEditMode} onClick={onClickAddRow} color="primary"><AddIcon />Add New</Button> : null }
                { showOptions ? options : null }
            </GridToolbarContainer>
        </Collapse>
      );
    }

    const onEdit = (event, id) => {
      event.stopPropagation();
      apiRef.current.setRowMode(id, 'edit');
      toggleUneditableColumns(true);
      setIsEditMode(true);
    };

    const onConfirmDelete = () => {
      const id = deleteId;
      const row = apiRef.current.getRow(deleteId);
      if (row.id && onDeleteRow) {
        onDeleteRow(row.id);
      }

      apiRef.current.updateRows([{ id, _action: 'delete' }]);
      setDeleteId(null);
    }

    const onDelete = (event, id) => {
      event.stopPropagation();
      setDeleteId(id);
    };

    const handleRowEditStart = (params, event) => {
      event.defaultMuiPrevented = true;
    };
  
    const handleRowEditStop = (params, event) => {
      event.defaultMuiPrevented = true;
    };

    const onCancel = (event, id) => {
      event.stopPropagation();
      apiRef.current.setRowMode(id, 'view');
  
      const row = apiRef.current.getRow(id);
      if (row.isNew) {
        apiRef.current.updateRows([{ id, _action: 'delete' }]);
      }

      toggleUneditableColumns(false);
      setIsEditMode(false);
    };

    const onSave = (event, id) => {
      event.stopPropagation();
      apiRef.current.commitRowChange(id);
      apiRef.current.setRowMode(id, 'view');
  
      const row = apiRef.current.getRow(id);
      if (row.isNew && onSaveNewRow) {
        row.isNew = false;
        onSaveNewRow(row);
      } else if (onUpdateRow) {
        onUpdateRow(row);
      }
      apiRef.current.updateRows([{ ...row, isNew: false, id }]);
      toggleUneditableColumns(false);
      setIsEditMode(false);
    };

    const hideActionsColumn = !isEditable && propActions.length === 0 || (propActions.length === 0 && !isEditMode);
    const editActionsColumn = [{
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: (isEditable ? 150 : 100) + (propActions.length * 50),
      disableExport: true,
      disableReorder: true,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      hide: hideActionsColumn,
      renderCell: (param) => {
        const { row } = param;
        const isActiveEditRow = apiRef.current.getRowMode(row.id) === 'edit';

        const actions = propActions.map(a => <Button 
          disabled={isEditMode || (a.isDisabled ? a.isDisabled(a) : false)} size='small' onClick={(e) => a.onClick(e, row)}>
            {a.icon}
        </Button>);

        if (isEditable) {
          if (!isActiveEditRow) {
            return <div>
              {actions}
              {
                onUpdateRow ? 
                  <Button
                    disabled={isEditMode}
                    size='small'
                    onClick={(e) => onEdit(e, row.id)}
                  >
                    <EditIcon />
                  </Button> : null
              }
              {
                onDeleteRow ? 
                <Button
                  disabled={isEditMode}
                  size='small'
                  onClick={(e) => onDelete(e, row.id)}
                >
                  <DeleteIcon />
                </Button> : null
              }
              </div>;
          }
  
          return <div>
              {actions}
              <Button
                size='small'
                onClick={(e) => onCancel(e, row.id)}
              >
                <CancelIcon />
              </Button>
              <Button
                size='small'
                onClick={(e) => onSave(e, row.id)}
              >
                <SaveIcon />
              </Button>
            </div>;
        } else {
          return <div>{actions}</div>
        }
      },
    }];

    const defaultColOpts = { headerAlign: 'center', width: 150, resizable: true, disableColumnMenu: true };
    const notSortableOpts = { sortable: false, hideSortIcons: true };

    const colData = useMemo(() => columns.map(colDef => {
      const { sortable } = colDef;
      return { ...defaultColOpts, ...sortable === false ? notSortableOpts : {}, ...colDef };
    }), [columns]);

    return <>
      <Typography variant="h4" color="primary" style={{ marginBottom: '10px'}}>{title}</Typography>
      <DataGridPro
        apiRef={apiRef}
        autoHeight
        components={{
          Toolbar: CustomToolbar,
        }}
        columns={[ ...hideActionsColumn ? [] : editActionsColumn, ...colData]}
        pageSize={pageSize}
        rows={data}
        hideFooterSelectedRowCount
        editMode="row"
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        pagination
      />
      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={typeof deleteId === 'string'}
      >
        <DialogTitle id="confirmation-dialog-title">Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this row?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteId(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirmDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
}