import React,{ useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import Table from 'components/Table';

export default function Users(props) {
  const { api } = useContext(FirebaseContext);
  const { t } = useTranslation();

  const {
    addUser,
    editUser, 
    deleteUser,
    checkUserExists
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const usersdata = useSelector(state => state.usersdata);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(usersdata.users){
      setData(usersdata.users.filter(user => user.usertype ==='rider'));
    }else{
      setData([]);
    }
  },[usersdata.users]);

  const addNewUser = (newData) => {
    newData['createdByAdmin'] = true;
    newData['usertype'] = 'rider';
    newData['createdAt'] = new Date().toISOString();
    newData['referralId'] = newData.firstName.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
    dispatch(addUser(newData));
  }

  const onUpdate = (newData) => {
    checkUserExists(newData)
      .then((res) => {
        if (res.users && res.users.length > 0 && !res.error) {
          dispatch(editUser(newData.id, newData));
        }
        else if(res.error){
          const msg = res.error;
          alert(msg);
        }
        else {
          addNewUser(newData);
        }
    });
  };

  const onDelete = (id) => {
    if (settings.AllowCriticalEditsAdmin) {
      dispatch(deleteUser(id));
    } else {
      alert(t('Demo Mode'));
    }
  };

  const onAddRow = (newData) => {
    checkUserExists(newData)
      .then((res) => {
        if (res.users && res.users.length > 0) {
          alert('User already exsits!');
        }
        else if(res.error){
          const msg = res.error;
          alert(msg);
        }
        else {
          addNewUser(newData);
        }
    });
  };

  const columns = [
    // { headerName: 'Created', field: 'createdAt', sortable: true, editable: false, width: 175, renderCell: ({value}) => value ? moment(value).format('lll') : null },
    { headerName: 'First', field: 'firstName', editable: true },
    { headerName: 'Last', field: 'lastName', editable: true },
    { headerName: 'Email', field: 'email', editable: true, width: 200, renderCell: ({ value }) => settings.AllowCriticalEditsAdmin ? value : "Hidden for Demo" },
    { headerName: 'Mobile', field: 'mobile', editable: true, renderCell: ({ value }) => settings.AllowCriticalEditsAdmin ? value: "Hidden for Demo" },
    { headerName: 'Profile Img',  field: 'profile_image', editable: false, renderCell: ({ value }) => value ? <img alt='Profile' src={value} style={{width: 50, borderRadius:'50%'}}/> : null},
    { headerName: 'Approval',  field: 'approved', type:'boolean', editable: true },
    { headerName: 'Rate by Distance',  field: 'rate_per_unit_distance', type:'numeric' , editable: true },
    { headerName: 'Rate per Hour',  field: 'rate_per_hour', type:'numeric' , editable: true },
    { headerName: 'Points',  field: 'walletBalance', type:'numeric' , editable: false, initialEditValue: 0},
    { headerName: 'Referral', field: 'signupViaReferral', editable: false },
    { headerName: 'Referral ID',  field: 'referralId', editable: false, initialEditValue: '' },
  ];

  return (
    usersdata.loading ? <CircularLoading/> :
    <Table
      title={'Manage Riders'}
      columns={columns}
      data={data}
      isEditable
      onSaveNewRow={onAddRow}
      onUpdateRow={onUpdate}
      onDeleteRow={onDelete}
    />
  );
}
