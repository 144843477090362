import React,{ useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import Table from '../components/Table';

export default function Notifications() {
  const { api } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    sendNotification,
    editNotifications
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);
  const columns =  [
      { headerName: t('Date'), field: 'date', editable: true, type: "date" },
      {
        headerName: t('device_type'),
        field: 'devicetype',
        type: 'singleSelect',
        valueOptions: [ 'All', 'Android', 'iOS' ],
        editable: true
      },
      {
        headerName: t('user_type'),
        field: 'usertype',
        type: 'singleSelect',
        valueOptions: ['All', t('rider'), t('driver')],
        editable: true
      },
      { headerName: t('title'), field: 'title', editable: true },
      { headerName: t('body'), field: 'body', editable: true  },
  ];

  const [data, setData] = useState([]);
  const notificationdata = useSelector(state => state.notificationdata);
  const dispatch = useDispatch();

  useEffect(()=>{
        if(notificationdata.notifications){
            setData(notificationdata.notifications);
        }else{
            setData([]);
        }
  },[notificationdata.notifications]);

  const onRowAdd = (newData) => {
    settings.AllowCriticalEditsAdmin ?
      dispatch(sendNotification(newData))
      :
      alert(t('demo_mode'));
    dispatch(editNotifications(newData,"Add"));
  }

  const onRowUpdate = (newData) => {
    dispatch(editNotifications(newData,"Update"));
  }

  const onDeleteRow = (id) => {
    dispatch(editNotifications({ id: id },"Delete"));
  }

  return (
    notificationdata.loading ? <CircularLoading/> :
    <Table 
      title={"Notification Scheduler"}
      columns={columns}
      data={data}
      isEditable
      onSaveNewRow={onRowAdd}
      onUpdateRow={onRowUpdate}
      onDeleteRow={onDeleteRow}
      showOptions={false}
      quickSearch={false}
    />
  );
}
