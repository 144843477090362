import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import Table from '../components/Table';
import { generateRandomId } from '../utils';

export default function CancellationReasons() {
  const { api } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    editCancellationReason
  } = api;
  const columns = [
    { headerName: t('reason'), flex: 1, field: 'label', editable: true }
  ];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const cancelreasondata = useSelector(state => state.cancelreasondata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (cancelreasondata.complex) {
      cancelreasondata.complex.forEach(val => val.id = generateRandomId());
      setData(cancelreasondata.complex);
    }else{
      setData([]);
    }
  }, [cancelreasondata.complex]);

  const onRowAdd = (newData) => {
    const tblData = JSON.parse(JSON.stringify(data));
    newData.value = tblData.length
    tblData.push(newData);
    dispatch(editCancellationReason(tblData, "Add"));
  }

  const onRowUpdate = (newData) => {
    const tblData = JSON.parse(JSON.stringify(data));
    tblData[tblData.findIndex(row => newData.id === row.id)] = newData;
    dispatch(editCancellationReason(tblData, "Update"));
  }

  const onDeleteRow = (id) => {
    const tblData = JSON.parse(JSON.stringify(data));
    tblData.splice(tblData.findIndex(row => row.id === id), 1);
    for(let i=0;i<tblData.length;i++){
      tblData[i].value = i;
    }
    dispatch(editCancellationReason(tblData, "Delete"));
  }

  return (
    cancelreasondata.loading ? <CircularLoading /> :
      <Table
        // title={t('cancellation_reasons')}
        title={'Canellation Options'}        
        columns={columns}
        data={data}
        isEditable={settings.AllowCriticalEditsAdmin}
        onSaveNewRow={onRowAdd}
        onUpdateRow={onRowUpdate}
        onDeleteRow={onDeleteRow}
      />
  );
}
